@import './colors.scss';

.home {

    width: 100vw;
    height: 100vh;
    padding: 40px;

    @media (max-width: 600px) {
        padding: 20px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-top {
        position: fixed;
        top: 0;
        left: 0;

        margin: 40px;
        height: calc(100% - 80px);

        @media (max-width: 600px) {
            margin: 20px;
            height: calc(100% - 40px);
        }

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        color: $black;

        &>div:first-child {
            &>span {
                display: block;

                font-family: 'Inter Tight', Helvetica, sans-serif;
                letter-spacing: .025rem;
                font-size: 1.5rem;
                line-height: 2.1rem;
                margin-bottom: 1.2rem;

                & a {
                    text-decoration: none;
                    font-weight: 400;

                    color: $black;

                    &:hover {
                        text-decoration: underline;
                        color: $blue !important;
                    }

                    &:visited {
                        color: $black
                    }
                }
            }

            &>span:first-child {
                font-weight: 500;
                color: $black;
            }

            &>span:not(:first-child) {
                font-weight: 300;
                color: $gray;
            }

        }

        &>div:last-child {
            display: flex;
            align-items: center;
            gap: 15px;


            &>*:hover {
                cursor: pointer;
                color: $blue
            }
        }
    }

    &-img {
        width: 100%;
        height: 500px;
        object-fit: cover;
        object-position: center;

        @media (max-width: 600px) {
            height: 350px
        }
    }

    &-center {
        width: 762px;
        padding-top: 27px;

        @media (max-width: 900px) {
            width: 100%
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        color: $black;


        &-project {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            gap: 30px;
            padding-top: 40px;

            &>svg:first-child:hover,
            &>svg:last-child:hover {
                cursor: pointer;
                filter: invert(.5)
            }

            @media (max-width: 600px) {
                gap: 20px;
            }

            &-text {
                color: $gray;
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                letter-spacing: 0.02em;
                text-transform: uppercase;
                max-width: calc(100% - 150px);

                @media (max-width: 600px) {
                    font-size: 18px;
                    line-height: 22px;
                    max-width: calc(100% - 100px);
                }

                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    color: $black;
                }

                &>span:first-child {
                    color: $black;
                    font-weight: 700;
                }
            }
        }
    }
}